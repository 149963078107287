import React from 'react'
import '../components/Styles/gridlex.scss'
import '../components/Styles/global.css' 

import NovaLayout from '../components/NovaLayout'
// import { PageHeader } from '../components/PageHeader'
import HubspotForm from 'react-hubspot-form'


const Index = () => {
  return (
    <NovaLayout title="Contact Us | Nova Digital Agency"  description="A digital agency designed for growth-driven mortgage businesses">
      <div className="layout-body">
        <div className="pt-2 xs-pt-2">
        <h3 className="sm-center xs-center mb-3 xs-mb-1">Let's get to know you.</h3>
          <div className="grid">
            <div className="hubspot-contact-form">
              <HubspotForm
                portalId='6296607'
                formId='edd4c71b-7f15-4ffa-ba78-1e8a24abe396'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
              />
            </div>
          </div>
        </div>
      </div>
      </NovaLayout>
  )
}

export default Index
